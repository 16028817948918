<template>
  <div class="deposit-awards" :class="[size, platform, direction]">
    <div v-if="props.awards.main" class="amount text-gold">
      <span v-if="isStepBonus" class="amount-to">
        {{ t('bonuses.toAmount') }}
      </span>
      <div class="amount-value">
        <StIcon
          v-if="props.awards.main.icon"
          :name="props.awards.main.icon"
          :size="bigIconSize"
        />
        {{ props.awards.main.amount }}
      </div>
    </div>
    <div v-if="props.awards.others.length" class="sub-bonuses">
      <div
        v-for="(subBonus, index) in props.awards.others"
        :key="index"
        class="sub-bonus"
      >
        +
        <span v-if="isStepBonus">{{ t('bonuses.toAmount') }}</span>
        <StIcon
          v-if="subBonus?.icon"
          :name="subBonus.icon"
          :size="smallIconSize"
        />
        <span>{{ subBonus?.amount }}</span>
        <span v-if="subBonus?.text" class="sub-bonus-text">
          {{ subBonus.text }}
        </span>
        <span v-if="index + 1 < props.awards.others.length">,</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  awards: {
    main: {
      icon: string | null
      amount: string
      amountWithCurrency: string
      text: string
    }
    others: {
      icon: string | null
      amount: string
      amountWithCurrency: string
      text: string
    }[]
  }
  isStepBonus?: boolean
  size?: 's' | 'm' | 'l' | 'xl'
  direction?: 'row' | 'column'
}

const props = withDefaults(defineProps<Props>(), {
  isStepBonus: false,
  size: 'l',
  direction: 'row',
})
const { isMobile, platform } = usePlatform()
const { t } = useI18n()

const smallIconSize = computed(() => {
  if (props.size === 'm') return isMobile.value ? 14 : 16

  return 16
})

const bigIconSize = computed(() => {
  if (props.size === 's') return isMobile.value ? 20 : 28

  return isMobile.value ? 24 : 36
})
</script>

<style scoped>
.text-gold {
  background: linear-gradient(
    109deg,
    #ffe86d 17.82%,
    #f8db90 42.56%,
    #feb581 72.33%,
    #f09f7c 81.52%
  );
  background-clip: text;

  -webkit-text-fill-color: transparent;
}

.amount {
  display: flex;
  gap: var(--spacing-125);
  align-items: flex-end;
  justify-content: center;

  font: var(--desktop-text-4xl-semibold);
}

.amount-value {
  display: flex;
  gap: var(--spacing-125);
  align-items: center;
}

.amount-to {
  font: var(--desktop-text-2xl-semibold);
}

.sub-bonuses {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-050);
  justify-content: center;

  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
  text-align: center;
  text-overflow: ellipsis;
}

.sub-bonus {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
}

.sub-bonus-text {
  text-transform: lowercase;
}

.deposit-awards {
  &.m {
    .amount {
      justify-content: flex-start;

      /* stylelint-disable */
      font-family: 'SF Pro Display';
      font-size: 36px;
      font-weight: 600;
      font-style: normal;
      line-height: 36px;

      /* stylelint-enable */
    }

    .amount-to {
      font: var(--desktop-text-xl-semibold);
    }

    .sub-bonuses {
      font: var(--desktop-text-sm-medium);
    }

    .sub-bonus-text {
      overflow: hidden;
      max-width: 140px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.xl {
    .amount-to {
      font: var(--desktop-text-3xl-semibold);
    }

    .amount {
      font: var(--desktop-text-5xl-semibold);
    }

    .sub-bonuses {
      font: var(--desktop-text-lg-semibold);
    }
  }

  &.mobile {
    .amount {
      justify-content: flex-start;

      /* stylelint-disable */
      font-family: 'SF Pro Display';
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
      line-height: 36px;

      /* stylelint-enable */
    }

    .sub-bonuses {
      justify-content: flex-start;
      font: var(--mobile-text-regular);
    }

    .amount-to {
      font: var(--desktop-text-2xl-semibold);
    }

    &.m {
      .amount {
        /* stylelint-disable */
        font-family: 'SF Pro Display';
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        line-height: 28px;

        /* stylelint-enable */
      }

      .amount-to {
        font: var(--mobile-title-2-semibold);
      }

      .sub-bonuses {
        font: var(--mobile-caption-1-medium);
      }
    }

    &.xl {
      .amount-to {
        font: var(--desktop-text-2xl-semibold);
      }

      .amount {
        font: var(--desktop-text-4xl-semibold);
      }

      .sub-bonuses {
        font: var(--mobile-headline-semibold);
      }
    }
  }

  /* stylelint-disable no-descending-specificity */
  &.column {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-100);

    .sub-bonuses {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-025);
    }

    &.m {
      gap: var(--spacing-075);
    }

    &.xl {
      gap: 0;

      .sub-bonuses {
        align-items: center;
      }
    }
  }
  /* stylelint-enable no-descending-specificity */
}
</style>
